import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/vkuramshin/p/www/tkcrm/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "введение"
    }}>{`Введение`}</h1>
    <h2 {...{
      "id": "краткое-описание"
    }}>{`Краткое описание`}</h2>
    <p>{`Спутниковый мониторинг объектов - одно из основных направлений `}<strong parentName="p">{`TKCRM`}</strong>{`. Система позволяет отслеживать любые объекты с использованием `}<strong parentName="p">{`телематических устройств`}</strong>{` и `}<strong parentName="p">{`мобильных приложений`}</strong>{` на базе Android и IOS.`}</p>
    <p><img alt="Monitoring" src={require("../public/images/monitoring-1.png")} /></p>
    <h2 {...{
      "id": "объекты-мониторинга"
    }}>{`Объекты мониторинга`}</h2>
    <ul>
      <li parentName="ul">{`Транспортные средства (спецтехника, легковые машины, мототехника и т.д.)`}</li>
      <li parentName="ul">{`Курьеры (Пешие, мобильные)`}</li>
      <li parentName="ul">{`Ж/Д вагоны`}</li>
    </ul>
    <p>{`и др...`}</p>
    <h2 {...{
      "id": "подключение-устройств"
    }}>{`Подключение устройств`}</h2>
    <p>{`Взаимодействие происходит следующим образом. Данные с устройств поступают из двух источников:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/monitoring/gps"
        }}>{`GPS оборудование`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/monitoring/mobileapp"
        }}>{`Мобильные приложения`}</a></li>
    </ul>
    <h2 {...{
      "id": "особенности-подключения"
    }}>{`Особенности подключения`}</h2>
    <ol>
      <li parentName="ol">{`Данные должны отправляться в хронологическом порядке (от старых к новым)`}</li>
      <li parentName="ol">{`Для более точных координат старайтесь не использовать координаты GSM сети`}</li>
    </ol>
    <h3 {...{
      "id": "пример-настроек-для-galileo-sky"
    }}>{`Пример настроек для Galileo Sky:`}</h3>
    <p><img alt="Galileo Sky tracker settings" src={require("../public/images/gs-settings-tracker.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      